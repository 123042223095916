import axiosIns from "@/libs/axios";

const get = async ({ estado }) => {
  let query = estado != null ? `?estado=${estado}` : ''
  return await axiosIns.get(`metodo-pagos${query}`)
}

const store = async (data) => {
  return await axiosIns.post('metodo-pagos', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`metodo-pagos/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`metodo-pagos/${id}`)
}
  
export default {
  get,
  store,
  update,
  destroy
}
