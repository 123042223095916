<template>
  <b-card>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">¡Operación no iniciada! 🚫💰</h2>

          <p>
            ¡Tienes que inciar operaciones para poder realizar {{ operacion }}!
          </p>

          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            :to="{ path: '/gestion-operaciones' }"
          >
            Ir a Gestión de Operaciones
          </b-button>

          <b-img fluid :src="img" alt="Not authorized page" />
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BLink, BImg, BButton } from "bootstrap-vue";
import store from "@/store/index";

export default {
  name: "OperacionNoIniciada",
  components: {
    BCard,
    BLink,
    BImg,
    BButton,
  },
  props: {
    operacion: {
      type: String,
      required: true,
      validator: (value) => {
        return ["ventas", "compras"].includes(value);
      },
    },
  },
  data() {
    return {
      appName: process.env.VUE_APP_NOMBRE_EMPRESA,
    };
  },
  computed: {
    img() {
      return store.state.appConfig.layout.skin === "dark"
        ? require("@/assets/images/pages/coming-soon-dark.svg")
        : require("@/assets/images/pages/coming-soon.svg");
    },
  },
};
</script>

<style lang="scss" scoped>
$misc-inner-max-width: 750px;

.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: calc(100vh - 7rem);
  width: 100%;
  align-items: center;
  justify-content: center;

  .misc-inner {
    position: relative;
    max-width: $misc-inner-max-width;
  }
}
</style>