import axiosIns from "@/libs/axios";

const get = async ({tipo_transaccion, sede_id, fecha, usuario_id}) => {
  return await axiosIns.get(`compras?tipo_transaccion=${tipo_transaccion}&sede_id=${sede_id}&fecha=${fecha}&usuario_id=${usuario_id}`)
}

const getVendedor = async ({tipo_transaccion, sede_id, fecha, usuario_id}) => {
  return await axiosIns.get(`compras/vendedor?tipo_transaccion=${tipo_transaccion}&sede_id=${sede_id}&fecha=${fecha}&usuario_id=${usuario_id}`)
}

const store = async (data) => {
  return await axiosIns.post('compras', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

const update = async (id, data) => {
  return await axiosIns.patch(`compras/${id}`, data)
}

const destroy = async (id) => {
  return await axiosIns.delete(`compras/${id}`)
}

const findOne = async (id) => {
  return await axiosIns.get(`compras/${id}`)
}

const getProductos = async () => {
  return await axiosIns.get(`productos`)
}

const findOnePdf = async (id) => {
  return await axiosIns.get(`compras/pdf/${id}`)
}

export default {
  get,
  store,
  update,
  destroy,
  getProductos,
  findOnePdf,
  findOne,
  getVendedor
}
