<template>
  <div>
    <b-card no-body>
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="verPdf(item.id)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span class="align-middle">VER COMPRA</span>
                </span>
                <feather-icon icon="EyeIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('compra-anular')"
                @click="confirmarEliminar(item)"
                :disabled="buttonDisabled || item.estado != 1"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ANULAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="mt-1 mt-md-0">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="9" lg="8">
                <b-row>
                  <b-col cols="6" md="6" lg="5">
                    <b-form-group>
                      <b-form-datepicker
                        v-model="fecha"
                        placeholder="YYYY-MM-DD"
                        size="sm"
                        class="form-control"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        @input="listar"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" md="6" lg="7">
                    <b-form-group
                      class="mb-0"
                      label=""
                      label-align-sm="right"
                      label-for="filterInput"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        :formatter="textFormatter"
                        @input="updateSearchQuery"
                        type="search"
                        size="sm"
                        placeholder="Buscar.."
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          tipo="tipo"
          :items="items"
          :loading="loading"
          @selectItemVenta="selectItemVenta"
        />
      </b-card-text>
    </b-card>
    <ModalPdfTransaccion
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      @cerrarModalPdf="cerrarModalPdf"
    />
  </div>
</template>
  
  <script>
import ComprasMixin from "../mixins/index";
import CompraServices from "../services/index";
export default {
  name: "ListaComprasLayout",
  mixins: [ComprasMixin],
  props: {
    tipo: {
      type: String,
      default: "vistaVenderdor",
    },
  },
  methods: {
    async listarService(params) {
      return CompraServices.getVendedor(params);
    },
  },
  mounted() {
    this.usuario_id = this.mixing.id;
    this.listar();
  },
};
</script>
    
  <style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
    