<template id="card">
  <OperacionNoIniciada v-if="!$caja()" operacion="compras" />

  <b-card v-else no-body>
    <b-card-body>
      <b-card-text>
        <b-tabs content-class="mt-1" align="center">
          <b-tab title="NUEVA COMPRA" lazy>
            <hr />
            <NuevaCompraLayout></NuevaCompraLayout>
          </b-tab>
          <b-tab title="LISTA DE COMPRAS" lazy>
            <hr />
            <CompraVendedorLayout tipo="vistaVenderdor"></CompraVendedorLayout>
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>
  
<script>
import NuevaCompraLayout from "../layouts/NuevaCompraLayout.vue";
import CompraVendedorLayout from "../layouts/CompraVendedorLayout.vue";
import { BTabs, BTab, BCard, BCardText, BCardBody } from "bootstrap-vue";
import OperacionNoIniciada from "@/views/OperacionNoIniciada.vue";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardText,
    NuevaCompraLayout,
    CompraVendedorLayout,
    BCardBody,
    OperacionNoIniciada,
  },
};
</script>

<style scoped>
.card-header {
  padding: 1rem 0rem 0rem 1rem !important;
}
</style>