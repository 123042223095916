<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12" lg="6">
            <b-row>
              <b-col cols="12" class="border-right">
                <b-row class="mb-0-5">
                  <b-col cols="12" md="12">
                    <b-form-group
                      label="Cliente:"
                      label-for="numeroDocumentoComplemento"
                      label-cols="12"
                      label-cols-lg="2"
                    >
                      <v-select
                        id="cliente"
                        v-model="clienteItem"
                        label="razon_social_documento"
                        class="style-chooser select-size-sm"
                        placeholder="Buscar Cliente (Nombre, NIT, CI, CEX)"
                        :clearable="true"
                        :options="clienteItems"
                        @search="onSearchCliente"
                        @input="getClienteitem"
                      >
                        <template #no-options="{ search }">
                          <span v-if="search"
                            >lo siento, no hay opciones de coincidencia</span
                          >
                          <span v-else>Digite, para buscar al cliente</span>
                        </template>
                        <template #list-header="{ search }">
                          <li
                            style="text-align: center"
                            v-if="search && clienteItems.length == 0"
                            @click="getClienteitem(null)"
                            class="add-new-client-header cursor-pointer"
                          >
                            <span class="">Registrar Nuevo Cliente </span>
                          </li>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-row class="mb-0-5">
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="{ errors }"
                          name="Tipo de documento"
                          rules="required"
                        >
                          <b-form-group
                            label="Tipo Doc.:"
                            label-for="tipo_documento"
                            label-cols="12"
                            label-cols-lg="4"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-select
                              :disabled="disabledCliente"
                              id="tipo_documento"
                              size="sm"
                              v-model="formCliente.tipo_documento_id"
                              value-field="id"
                              text-field="descripcion"
                              :options="tipoDocumentoItems"
                              :state="errors.length > 0 ? false : null"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="8" md="4">
                        <b-form-group
                          label="Nro Doc:"
                          label-for="numero_documento"
                          label-cols="12"
                          label-cols-lg="3"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Numero de documento"
                            :rules="numeroDocumento"
                          >
                            <b-form-input
                              :disabled="disabledCliente"
                              size="sm"
                              id="numero_documento"
                              v-model="formCliente.numero_documento"
                              :state="errors.length > 0 ? false : null"
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4" md="2">
                        <b-form-group
                          label="Comp:"
                          label-for="complemento"
                          rules="max:5"
                          label-cols="12"
                          label-cols-lg="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Complemento"
                          >
                            <b-form-input
                              :disabled="
                                disabledCliente ||
                                formCliente.tipo_documento_id != 1
                              "
                              size="sm"
                              id="complemento"
                              :formatter="textFormatter"
                              lazy-formatter
                              placeholder=""
                              v-model="formCliente.complemento"
                              :state="errors.length > 0 ? false : null"
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mb-0-5">
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="Razon social:"
                          label-for="razon_social"
                          label-cols="12"
                          label-cols-lg="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Razon social"
                            rules="required"
                          >
                            <b-form-input
                              :disabled="disabledCliente"
                              size="sm"
                              id="razon_social"
                              :formatter="textFormatter"
                              lazy-formatter
                              v-model="formCliente.razon_social"
                              :state="errors.length > 0 ? false : null"
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="Correo:"
                          label-for="correo_electronico"
                          label-cols="12"
                          label-cols-lg="2"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Correo electronico"
                            rules="email|max:100"
                          >
                            <b-form-input
                              :disabled="disabledCliente"
                              size="sm"
                              id="correo_electronico"
                              v-model="formCliente.correo_electronico"
                              :state="errors.length > 0 ? false : null"
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="6">
            <b-row>
              <b-col cols="12" lg="8" class="border-right">
                <b-row>
                  <b-col cols="12" class="mb-0-5">
                    <validation-provider
                      #default="{ errors }"
                      name="Metodo de pago"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : null"
                        label="Metodo Pago:"
                        label-cols="12"
                        label-cols-lg="3"
                      >
                        <v-select
                          v-model="codigoMetodoPago"
                          class="style-chooser select-size-sm"
                          label="descripcion"
                          :clearable="false"
                          :options="metodoPagoItems"
                          @input="obtenerFormaPago"
                          :class="{ 'is-invalid': !!errors.length }"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" class="mb-0-5 hidden">
                    <!-- :rules="ruleTarjeta" -->
                    <validation-provider
                      #default="{ errors }"
                      name="Tarjeta de crédito"
                      rules=""
                    >
                      <b-form-group
                        label="Nro. Tarjeta:"
                        label-cols="12"
                        label-cols-lg="3"
                      >
                        <b-form-input
                          :disabled="disableTarjeta"
                          v-model="transaccion.nro_tarjeta"
                          maxlength="16"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          onClick="this.select();"
                          placeholder="0000 0000 0000 0000"
                          @input="tarjetaCredito"
                          :class="{ 'is-invalid': !!errors.length }"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="comprobante"
                      rules="required"
                    >
                      <b-form-group
                        label="Comprobante:"
                        label-for="comprobante"
                        label-cols="12"
                        label-cols-lg="6"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-select
                          id="comprobante"
                          size="sm"
                          v-model="transaccion.comprobante"
                          value-field="value"
                          text-field="text"
                          :options="comprobanteitems"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" class="mb-0-5">
                    <validation-provider
                      #default="{ errors }"
                      name="numero_comprobante"
                      rules="required"
                    >
                      <b-form-group
                        label="Nro. Comprobante:"
                        label-for="numero_comprobante"
                        label-cols="12"
                        label-cols-lg="6"
                        label-align-xl="right"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          size="sm"
                          id="numero_comprobante"
                          v-model="transaccion.numero_comprobante"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" class="mb-0-5">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label="Fecha compra:"
                      label-for="fecha_compra"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="fecha_compra"
                        rules="required"
                      >
                        <b-form-datepicker
                          id="validez"
                          v-model="transaccion.fecha"
                          :max="maxDate"
                          label-no-date-selected="Seleccione una fecha"
                          label-help="Use las teclas del cursor para navegar por las fechas del calendario"
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label="Imagen Comprobante:"
                      label-for="imagen"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="imagen"
                        rules=""
                      >
                        <b-form-file
                          size="sm"
                          label="Imagen"
                          @change="cambioDeImagen"
                          id="imagen"
                          v-model="transaccion.imagen"
                          accept="image/*"
                          placeholder="Elija una imagen"
                          drop-placeholder="Ningún imagen seleccionado"
                          no-drop-placeholder="asda"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="contenedor-imagen boxs1 mb-0-5">
                  <b-img
                    v-if="imagenPreview"
                    :src="imagenPreview"
                    center
                    thumbnail
                    fluid
                    class="rounded"
                    alt="Sin imagen"
                  />

                  <b-img
                    v-else
                    v-bind="mainProps"
                    rounded
                    alt="Rounded image"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <b-form-textarea
                  id="observaciones"
                  placeholder="Observaciones"
                  size="sm"
                  v-model="transaccion.observaciones"
                  lazy-formatter
                  :formatter="textFormatter"
                  rows="1"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr class="hr" />

        <b-row class="mb-0-5">
          <b-col cols="12" lg="6">
            <b-row>
              <b-col cols="6" md="6">
                <b-form-group
                  label="Código (Esc):"
                  label-for="codigo"
                  label-cols="12"
                  label-cols-lg="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MaximizeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="codigo"
                      v-model="codigo"
                      @keyup.enter="buscarCodigo()"
                      onClick="this.select();"
                      size="sm"
                      placeholder="Digite o Escanee el código"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="6" LG="3">
                <b-button
                  class="btnBuscarArticulo"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="dark"
                  size="sm"
                  block
                  @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
                >
                  BUSCAR ARTICULO (F9)
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col cols="12">
            <ag-grid-vue
              :gridOptions="gridOptions"
              class="ag-theme-balham"
              :class="{
                'ag-grid-tableCompra': !pedido,
                'ag-grid-tableCompraPedido': pedido,
              }"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              @grid-ready="onGridReady"
              :rowData="transaccion.detalleTransaccion"
              :suppressRowClickSelection="true"
              colResizeDefault="shift"
              :animateRows="false"
              :floatingFilter="false"
              :rowClassRules="rowClassRules"
              @rowClicked="onRowClicked($event)"
              :alwaysShowVerticalScroll="true"
              @first-data-rendered="onFirstDataRendered"
              :getRowStyle="getRowStyle"
              :pinnedBottomRowData="pinnedBottomRowData"
              @cell-value-changed="onCellValueChanged"
              @cell-key-down="onCellKeyDown"
            >
            </ag-grid-vue>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col cols="12" md="4">
            <b-form-group style="text-align: end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="dark"
                class="mr-1"
                @click.prevent="validar"
              >
                REALIZAR COMPRA
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <ModalSelectArticulo
      ref="ModalSelectArticuloRef"
      esServicio="0"
      :ocultarCostoColumna="$hascan('venta-ver_costo')"
      @selectItemArticulo="selectItemArticulo"
      :giroComercialId="mixing.giroComercialId"
    />
    <ModalPdfTransaccion
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      @cerrarModalPdf="cerrarModalPdf"
    />
  </div>
</template>
<script>
import CompraServices from "../services/index";
import ProductoServices from "@/modules/administracion/producto/services/index";
import { ProveedorService } from "@/modules/administracion/cliente_proveedor/services/index";
import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ModalSelectArticulo from "@/modules/components/ModalSelectArticulo.vue";
// import flatPickr from "vue-flatpickr-component";
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardGroup,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSpinbutton,
  BFormRadio,
  BFormCheckbox,
  BTable,
  BInputGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge,
  BForm,
  BOverlay,
  BImg,
  BInputGroupAppend,
  VBTooltip,
  BInputGroupPrepend,
  BFormFile,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { required } from "@validations";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "ag-grid-vue";
import MetodoPagoServices from "@/modules/configuracion-sin/services/metodoPago";
import TipoDocumentoService from "@/modules/configuracion/tipo-documento/services/index";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BCardFooter,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    Cleave,
    BFormSpinbutton,
    BFormRadio,
    BFormCheckbox,
    BTable,
    VuePerfectScrollbar,
    BInputGroup,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    BImg,
    BInputGroupAppend,
    VBTooltip,
    AgGridVue,
    BInputGroupPrepend,
    ModalSelectArticulo,
    ModalPdfTransaccion,
    BFormFile,
    BFormDatepicker,
    BFormTextarea,
    // flatPickr,
  },
  props: {
    pedido: {
      type: [Object, Array],
      default: () => {},
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
      // modal articulo
      item: [],
      // table
      required,
      loading: false,
      metodoPagoItems: [],

      // grid
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,
      gridApi: null,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: false,
      },
      columnDefs: [
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          filter: false,
          valueFormatter: cantidadNumberFormatter,
          valueParser: cantidadParser,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "PRODUCTO",
          field: "descripcion",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "LOTE",
          field: "lote",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "VENCIMIENTO",
          field: "vencimiento",
          valueFormatter: function (params) {
            if (params.node.rowPinned) {
              return "";
            } else {
              if (params.value == "" || params.value == null) {
                params.data.vencimiento = "";
                return "";
              } else {
                const formattedDate = moment(
                  params.value,
                  "YYYY/MM/DD",
                  true
                ).format("YYYY/MM/DD");
                if (formattedDate === "Invalid date") {
                  params.data.vencimiento = "";
                  return "";
                } else {
                  return formattedDate;
                }
              }
            }
          },
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "COSTO FAC/UNIT",
          field: "costo_facturado",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
          valueFormatter: precioNumberFormatter,
          valueParser: costoParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "DESCUENTO",
          field: "descuento",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: descuentoNumberFormatter,
          valueParser: descuentoParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "COSTO UNIT",
          field: "costo",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: precioNumberFormatter,
          valueGetter: (params) => {
            if (!params.node.rowPinned) {
              params.data.costo =
                this.transaccion && this.transaccion.comprobante == "FACTURA"
                  ? (params.data.costo_facturado -
                      params.data.descuento / params.data.cantidad) *
                    (1 - this.transaccion.iva)
                  : params.data.costo_facturado;
              return params.data.costo;
            } else {
              return undefined;
            }
          },
          editable: function (param) {
            if (!param.node.rowPinned) {
              return false;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "SUBTOTAL IMPTO",
          field: "subtotal_impuesto",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
          valueFormatter: subtotalImpuestoNumberFormatter,
          valueGetter: (param) => {
            if (!param.node.rowPinned) {
              param.data.subtotal_impuesto =
                param.data.cantidad *
                (param.data.costo_facturado -
                  param.data.descuento / param.data.cantidad) *
                this.transaccion.iva;
              return param.data.subtotal_impuesto;
            } else {
              return param.data.subtotal_impuesto;
            }
          },
        },
        {
          headerName: "SUBTOTAL",
          field: "subtotal",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: numberFormatter,
          editable: function (param) {
            if (param.node.rowPinned) {
              if (param.node.data.edit) {
                return true;
              }
              return false;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
          valueGetter: (param) => {
            if (!param.node.rowPinned) {
              param.data.subtotal =
                this.transaccion && this.transaccion.comprobante == "FACTURA"
                  ? param.data.cantidad * param.data.costo
                  : param.data.cantidad * param.data.costo -
                    param.data.descuento;
              return param.data.subtotal;
            }
            return param.node.data.subtotal;
          },
        },
        {
          headerName: "",
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            } else {
              return `<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">&times;</button>`;
            }
          },
        },
      ],
      // end grid
      submitted: false,
      // transaccion
      transaccion: {
        cliente_proveedor_id: null,
        usuario_id: null,
        codigo_metodo_pago: "",
        metodo_pago: "",
        metodo_pago_id: "",
        tipo_transaccion_id: 1,
        nro_tarjeta: null,
        subtotal: 0,
        descuento: 0,
        total: 0,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        comprobante: "",
        numero_comprobante: "",
        sede_id: "",
        efectivo: "0.00",
        tarjeta: "0.00",
        credito: "0.00",
        qr: "0.00",
        otro: "0.00",
        cambio: "0.00",
        transferencia_bancaria: "0.00",
        imagen: null,
        iva: 0,
        total_impuesto: 0,
        total_pagado: 0,
        fecha: null,
        observaciones: "",
      },
      maxDate: null,

      tipoDocumentoItems: [],
      codigoMetodoPago: null,

      // variables de clientes
      nro_documento_complemento: "",
      cliente: "",
      disabledCliente: true,
      formCliente: {
        id: null,
        tipo_documento_id: null,
        numero_documento: "",
        complemento: "",
        razon_social: "",
        direccion: "",
        ciudad: "",
        zona: "",
        correo_electronico: "",
        celular: "",
        nro_documento_complemento: "",
        es_verificado: 0,
      },

      disableGitcard: false,
      disableTarjeta: true,
      ruleTarjeta: "",

      errores: [],
      codigo: "",
      productosPedidos: this.pedido
        ? this.pedido.transaccion.detalle_transaccion.map(
            (detalle) => detalle.producto
          )
        : [],
      comprobanteitems: [
        { text: "FACTURA", value: "FACTURA" },
        { text: "NOTA DE VENTA", value: "NOTA DE VENTA" },
        { text: "REMISION", value: "REMISION" },
        { text: "OTROS", value: "OTROS" },
      ],
      imagenPreview: null,
      mainProps: {
        blank: true,
      },
      formData: [],
      clienteItems: [],
      clienteItem: null,
      debounce: null,
    };
  },
  created: function () {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
    window.addEventListener("keyup", this.onkey);

    this.llenarDatosPedidos();
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  watch: {
    "formCliente.tipo_documento_id": function () {
      if (this.formCliente.tipo_documento_id != 1) {
        if (this.formCliente.tipo_documento_id != "") {
          this.formCliente.complemento = "";
        }
      }
      this.errores.tipo_documento_id = [];
    },
    "formCliente.numero_documento": function () {
      this.errores.numero_documento = [];
    },
    "formCliente.complemento": function () {
      this.errores.complemento = [];
    },
    "formCliente.correo_electronico": function () {
      this.errores.correo_electronico = [];
    },
    "formCliente.razon_social": function () {
      this.errores.razon_social = [];
    },
    "transaccion.imagen": function () {
      this.errores.imagen = [];
    },
    "transaccion.comprobante": function () {
      this.transaccion.iva =
        this.transaccion.comprobante == "FACTURA" ? 0.13 : 0;
      this.gridApi.refreshCells({ force: true });
      this.gridApi.redrawRows();
    },
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          subtotal_impuesto: "SUBTOTAL Bs",
          subtotal: this.calcularSubTotal(),
        },
        {
          subtotal_impuesto: "TOTAL IMPUESTO Bs",
          edit: false,
          subtotal: this.transaccion.total_impuesto,
        },
        {
          subtotal_impuesto: "TOTAL Bs",
          subtotal: this.calcularTotal(),
          bold: true,
        },
        {
          subtotal_impuesto: "DESCUENTO Bs",
          edit: true,
          subtotal: this.transaccion.descuento,
          key: "descuento",
        },
        {
          subtotal_impuesto: "TOTAL  PAGADO Bs",
          subtotal: this.transaccion.total_pagado,
          bold: true,
        },
      ];
    },
    numeroDocumento() {
      if (
        this.formCliente.tipo_documento_id == 1 ||
        this.formCliente.tipo_documento_id == 5
      ) {
        return "required|integer|max:20";
      } else {
        return "required|max:20";
      }
    },
  },
  methods: {
    onkey(event) {
      if (event.key == "F8") {
        document.getElementById("numeroDocumentoComplemento").focus();
        document.getElementById("numeroDocumentoComplemento").select();
      }
      if (event.key == "F9") {
        this.$refs.ModalSelectArticuloRef.focusBuscarArticulo();
      }
      if (event.key == "Escape") {
        document.getElementById("codigo").focus();
        document.getElementById("codigo").select();
      }
    },
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
          try {
            const response = await ProveedorService.buscar({
              q: search,
            });
            const data = response.data.map((g) => ({
              ...g,
              razon_social_documento:
                g.numero_documento + " - " + g.razon_social,
            }));
            this.clienteItems = data;
            loading(false);
          } catch (err) {
            console.log(err);
            loading(false);
          }
        }, 350);
      } else {
        loading(false);
        this.clienteItems = [];
      }
    },
    getClienteitem(item) {
      if (item == null) {
        setTimeout(() => {
          this.$nextTick(() => {
            document.getElementById("tipo_documento").focus();
          });
        }, 100);
        this.clienteItem = null;
        this.cliente = [];
        this.disabledCliente = false;
        this.formCliente = {
          id: null,
          tipo_documento_id: null,
          numero_documento: "",
          complemento: "",
          razon_social: "",
          correo_electronico: "",
          nro_documento_complemento: "",
          es_verificado: 0,
        };
      } else {
        this.disabledCliente = true;
        this.cliente = item;
        this.getCliente();
      }
    },
    // cliente
    async buscarDocumento() {
      try {
        const response = await ProveedorService.findOne(
          this.nro_documento_complemento
        );
        this.disabledCliente = true;
        this.cliente = response.data;
        this.getCliente();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ADVERTENCIA!",
          variant: "warning",
          oaster: "b-toaster-top-center",
          solid: false,
        });
        this.nro_documento_complemento;
        this.disabledCliente = false;
        this.formCliente = {
          id: null,
          tipo_documento_id: null,
          numero_documento: this.nro_documento_complemento,
          complemento: "",
          razon_social: "",
          correo_electronico: "",
          nro_documento_complemento: "",
          es_verificado: 0,
        };
      }
    },
    getCliente() {
      let complemento = this.cliente.complemento
        ? this.cliente.complemento
        : "";
      this.transaccion.cliente_proveedor_id = this.cliente.id;
      this.transaccion.razon_social = this.cliente.razon_social;
      this.transaccion.numero_documento = this.cliente.numero_documento;
      this.formCliente = {
        id: this.cliente.id,
        tipo_documento_id: this.cliente.tipo_documento_id,
        numero_documento: this.cliente.numero_documento,
        complemento: complemento,
        razon_social: this.cliente.razon_social,
        correo_electronico: this.cliente.correo_electronico,
        nro_documento_complemento: this.nro_documento_complemento,
        es_verificado: this.cliente.es_verificado ? 1 : 0,
      };
    },
    async getFormCliente() {
      this.formCliente.id = null;
      this.formCliente.nro_documento_complemento =
        this.formCliente.numero_documento + this.formCliente.complemento;
    },
    async registrarCliente() {
      await this.getFormCliente();
      try {
        const response = await ProveedorService.store(this.formCliente);
        this.transaccion.cliente_proveedor_id =
          response.data.cliente_proveedor.id;
        this.transaccion.razon_social =
          response.data.cliente_proveedor.razon_social;
        this.transaccion.numero_documento =
          response.data.cliente_proveedor.numero_documento;

        this.disabledCliente = true;
        await this.registrar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          oaster: "b-toaster-top-center",
          solid: false,
        });
        this.disabledCliente = false;
        this.submitted = false;
        this.errores = err.response.data.errors;
        if (this.errores.numero_documento !== undefined) {
          this.$bvToast.toast(this.errores.numero_documento[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.complemento !== undefined) {
          this.$bvToast.toast(this.errores.complemento[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.razon_social !== undefined) {
          this.$bvToast.toast(this.errores.razon_social[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.correo_electronico !== undefined) {
          this.$bvToast.toast(this.errores.correo_electronico[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
      }
    },

    // VALIDAR FORMULARIO
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.transaccion.detalleTransaccion.length > 0) {
            this.transaccion.efectivo =
              this.transaccion.metodo_pago_id == 1
                ? this.transaccion.total
                : "0.00";

            this.transaccion.tarjeta =
              this.transaccion.metodo_pago_id == 2
                ? this.transaccion.total
                : "0.00";

            this.transaccion.otro =
              this.transaccion.metodo_pago_id == 5
                ? this.transaccion.total
                : "0.00";

            this.transaccion.credito =
              this.transaccion.metodo_pago_id == 6
                ? this.transaccion.total
                : "0.00";

            this.transaccion.qr =
              this.transaccion.metodo_pago_id == 7
                ? this.transaccion.qr
                : "0.00";

            this.submitted = true;
            this.transaccion.sede_id = this.mixing.sede_id;
            if (this.disabledCliente) {
              this.registrarVenta();
            } else {
              this.registrarCliente();
            }
          } else {
            this.$bvToast.toast(" AGREGAR POR LO MENOS UN ARTICULO. ", {
              title: "ERROR!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: false,
            });
          }
        } else {
          this.$bvToast.toast(" COMPLETE LOS CAMPOS. ", {
            title: "ERROR!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      });
    },

    // registrar la venta
    async registrarVenta() {
      if (
        this.formCliente.tipo_documento_id == 5 &&
        !this.formCliente.es_verificado
      ) {
        // await this.verificarNit();
        await this.registrar();
      } else {
        await this.registrar();
      }
    },
    async registrar() {
      this.formularioDatos();
      try {
        const response = await CompraServices.store(this.formData); // this.transaccion
        this.errores = [];
        this.limpiarForm();
        setTimeout(() => {
          this.submitted = false;
          this.$bvToast.toast("La compra se realizo con corretamente", {
            title: "EXITO!",
            variant: "success",
            solid: false,
          });
          this.verPdf(response.data.id);
        }, 300);
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdf(id) {
      try {
        const response = await CompraServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // remover de grid
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    remover(index) {
      this.transaccion.detalleTransaccion.splice(index, 1);
    },
    // calculos de totales
    calcularSubTotal() {
      let resultado = 0;
      let resultadoImpuesto = 0;
      for (
        let index = 0;
        index < this.transaccion.detalleTransaccion.length;
        index++
      ) {
        resultado =
          resultado + this.transaccion.detalleTransaccion[index].subtotal;
        resultadoImpuesto =
          resultadoImpuesto +
          this.transaccion.detalleTransaccion[index].subtotal_impuesto;
      }
      resultadoImpuesto = resultadoImpuesto.toFixed(2);
      this.transaccion.total_impuesto = resultadoImpuesto;
      resultado = resultado.toFixed(2);
      // this.transaccion.subtotal = parseFloat(resultado);
      this.transaccion.subtotal = resultado;
      return resultado;
    },
    calcularTotal() {
      let resultado = 0;
      let resultadoTotalPagado = 0;
      resultado =
        parseFloat(this.transaccion.subtotal) +
        parseFloat(this.transaccion.total_impuesto);
      resultadoTotalPagado =
        parseFloat(this.transaccion.subtotal) -
        parseFloat(this.transaccion.descuento) +
        parseFloat(this.transaccion.total_impuesto);
      resultado = resultado.toFixed(2);
      resultadoTotalPagado = resultadoTotalPagado.toFixed(2);
      this.transaccion.total_pagado = resultadoTotalPagado;
      this.transaccion.total = resultado;
      return resultado;
    },

    onCellValueChanged(event) {
      if (event.data.key == "descuento") {
        this.transaccion.descuento = event.data.subtotal;
      }
    },
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },

    // TARJETA
    tarjetaCredito(value) {
      if (value.length == 4) {
        this.transaccion.nro_tarjeta = value + "00000000";
      }
    },

    // METODO PAGO
    obtenerFormaPago(value) {
      if (value == null) {
        // this.transaccion.codigo_metodo_pago = null;
        this.transaccion.metodo_pago_id = null;
        this.transaccion.nro_tarjeta = null;
        this.transaccion.metodo_pago = null;
        this.ruleTarjeta = "";
        this.disableTarjeta = true;
      } else {
        this.transaccion.nro_tarjeta = null;
        // this.transaccion.codigo_metodo_pago = value.codigo;
        this.transaccion.metodo_pago_id = value.id;
        this.transaccion.metodo_pago = value.descripcion;
        if (value.descripcion.includes("TARJETA")) {
          this.ruleTarjeta = "required|min:16|max:16";
          this.disableTarjeta = false;
        } else {
          this.ruleTarjeta = "";
          this.disableTarjeta = true;
        }
      }
    },

    // grid
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },

    // FORMATOS DE TEXTO EN MAUSCULAS Y DECIMALES
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },

    // limpiar formulario
    limpiarForm() {
      this.transaccion = {
        cliente_proveedor_id: null,
        codigo_metodo_pago: "",
        metodo_pago: "",
        metodo_pago_id: null,
        tipo_transaccion_id: 1,
        nro_tarjeta: null,
        subtotal: 0,
        descuento: 0,
        total: 0,
        usuario_id: this.mixing.id,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        comprobante: "",
        numero_comprobante: "",
        sede_id: this.mixing.sede_id,
        efectivo: "0.00",
        tarjeta: "0.00",
        credito: "0.00",
        qr: "0.00",
        otro: "0.00",
        cambio: "0.00",
        transferencia_bancaria: "0.00",
        iva: 0,
        total_impuesto: 0,
        total_pagado: 0,
        fecha: this.mixing.hoy,
        observaciones: "",
      };
      this.clienteItem = null;
      this.codigoMetodoPago = null;
      // this.codigoMetodoPago = {
      //   agrupador: "METODO PAGO",
      //   codigo: "1",
      //   codigoActividad: null,
      //   descripcion: "EFECTIVO",
      // };
      this.obtenerFormaPago(this.codigoMetodoPago);
      this.cliente = "";
      this.nro_documento_complemento = "";
      this.disabledCliente = true;
      this.disableGitcard = true;
      this.codigo = "";

      this.formCliente = {
        id: null,
        tipo_documento_id: null,
        numero_documento: "",
        complemento: "",
        razon_social: "",
        correo_electronico: "",
        nro_documento_complemento: "",
        es_verificado: 0,
      };

      this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
      this.$refs.simpleRules.reset();
      this.imagenPreview = "";
      this.imagen = null;
      this.formData = [];
    },

    // llamadas ala api de sifacturo
    async getDocumentoIdentidad() {
      try {
        const response = await TipoDocumentoService.get({ estado: 1 });
        this.tipoDocumentoItems = response.data;
      } catch (err) {
        console.log(err);

        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async getMetodosPago() {
      try {
        const response = await MetodoPagoServices.get({ estado: 1 });
        let metodoPagos = [1, 2, 5, 6, 7];
        let metodoPago = response.data.filter((value) =>
          metodoPagos.includes(value.id)
        );
        this.metodoPagoItems = metodoPago;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    // codigo de barra articulos
    async buscarCodigo() {
      if (this.codigo.length > 0) {
        try {
          const response = await ProductoServices.findOneCodigoNoStock(
            this.codigo,
            this.mixing.sede_id
          );
          this.selectItemArticulo(response.data, false);
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
    // encuentra articulo
    encuentraArticulo(id, estado) {
      var sw = 0;
      for (let i = 0; i < this.transaccion.detalleTransaccion.length; i++) {
        if (this.transaccion.detalleTransaccion[i].id == id) {
          this.transaccion.detalleTransaccion[i].cantidad++;
          sw = true;
          if (estado) {
            setTimeout(() => {
              this.focusAgridCantidad(i);
            }, 120);
          } else {
            setTimeout(() => {
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }, 120);
            this.gridApi.redrawRows();
          }
        }
      }
      return sw;
    },
    // Emit de select articulo
    selectItemArticulo(producto, estado) {
      if (producto) {
        setTimeout(() => {
          this.agregarItem(producto, estado);
        }, 100);
      } else {
        this.$bvToast.toast(" POR FAVOR SELECCIONE UN ARTICULO ", {
          title: "ADVERTENCIA  !",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: false,
        });
      }
    },
    agregarItem(producto, estado) {
      if (!this.encuentraArticulo(producto.id, estado)) {
        const detalle = this.pedido?.transaccion.detalle_transaccion.find(
          (detalle) => {
            if (detalle.producto.id == producto.id) {
              return detalle;
            }
          }
        );

        const cantidad = this.pedido ? detalle.cantidad : 1;

        const costo = this.pedido ? detalle.costo : producto.costo;

        const subtotal = costo * cantidad;

        this.transaccion.detalleTransaccion.push({
          id: producto.id,
          cantidad: cantidad,
          codigo: producto.codigo,
          descripcion: producto.descripcion,
          descuento: 0,
          costo_facturado: costo,
          costo: costo,
          precio: 0,
          lote: "",
          vencimiento: "",
          subtotal_impuesto:
            cantidad * (costo - 0 / cantidad) * this.transaccion.iva,
          subtotal: subtotal,
          unidad: producto.unidad,
        });
        if (estado) {
          setTimeout(() => {
            this.focusAgrid();
          }, 120);
        } else {
          setTimeout(() => {
            document.getElementById("codigo").focus();
            document.getElementById("codigo").select();
          }, 120);
          this.gridApi.redrawRows();
        }
      }
    },
    focusAgrid() {
      const cellrows = this.gridApi.getDisplayedRowCount();
      this.gridApi.setFocusedCell(cellrows - 1, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: cellrows - 1,
        colKey: "cantidad",
      });
    },
    focusAgridCantidad(i) {
      this.gridApi.setFocusedCell(i, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: i,
        colKey: "cantidad",
      });
    },
    // key pres
    onCellKeyDown(params) {
      if (params.event) {
        if (params.event.key == "Enter") {
          setTimeout(() => {
            document.getElementById("codigo").focus();
          }, 200);
        }
      }
    },

    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
      this.$emit("compra-registrada");
    },

    async buscarProductosPedidosPorIds(ids) {
      if (ids.length > 0) {
        try {
          const response = await ProductoServices.findByIds(
            ids,
            this.mixing.sede_id
          );
          const productos = response.data;

          productos.forEach((producto) => {
            this.selectItemArticulo(producto, false);
          });
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
    llenarDatosPedidos() {
      if (this.pedido) {
        const cliente = this.pedido.transaccion.cliente;

        this.formCliente = cliente;
        this.transaccion.cliente_proveedor_id = cliente.id;
        this.transaccion.razon_social = cliente.razon_social;
        this.transaccion.numero_documento = cliente.numero_documento;
        this.transaccion.pedido_id = this.pedido.id;
        this.codigoMetodoPago = this.pedido.transaccion.metodo_pagos;
        this.obtenerFormaPago(this.codigoMetodoPago);

        const productosIds = this.productosPedidos.map(
          (producto) => producto.id
        );
        this.buscarProductosPedidosPorIds(productosIds);
      }
    },

    cambioDeImagen(event) {
      if (event.target.files[0]) {
        let file = event.target.files[0];
        if (file == null) {
          this.transaccion.imagen = "";
          this.imagenPreview = "";
        } else {
          this.imagenPreview = URL.createObjectURL(file);
          this.transaccion.imagen = file;
        }
      } else {
        this.transaccion.imagen = "";
        this.imagenPreview = "";
      }
    },
    formularioDatos() {
      const formData = new FormData();

      formData.append(
        "cliente_proveedor_id",
        this.transaccion.cliente_proveedor_id
      );
      formData.append("usuario_id", this.transaccion.usuario_id);
      formData.append(
        "codigo_metodo_pago",
        this.transaccion.codigo_metodo_pago
      );
      formData.append("metodo_pago", this.transaccion.metodo_pago);
      formData.append("metodo_pago_id", this.transaccion.metodo_pago_id);
      formData.append(
        "tipo_transaccion_id",
        this.transaccion.tipo_transaccion_id
      );
      formData.append("nro_tarjeta", this.transaccion.nro_tarjeta);
      formData.append("subtotal", this.transaccion.subtotal);
      formData.append("descuento", this.transaccion.descuento);
      formData.append("total", this.transaccion.total);
      formData.append("razon_social", this.transaccion.razon_social);
      formData.append("numero_documento", this.transaccion.numero_documento);
      if (this.transaccion?.pedido_id) {
        formData.append("pedido_id", this.transaccion.pedido_id);
      }

      for (
        let index = 0;
        index < this.transaccion.detalleTransaccion.length;
        index++
      ) {
        const detalle = this.transaccion.detalleTransaccion[index];
        Object.keys(detalle).forEach((key) => {
          formData.append(`detalleTransaccion[${index}][${key}]`, detalle[key]);
        });
      }

      formData.append("comprobante", this.transaccion.comprobante);
      formData.append(
        "numero_comprobante",
        this.transaccion.numero_comprobante
      );
      formData.append("sede_id", this.transaccion.sede_id);
      formData.append("efectivo", this.transaccion.efectivo);
      formData.append("tarjeta", this.transaccion.tarjeta);
      formData.append("credito", this.transaccion.credito);
      formData.append("qr", this.transaccion.qr);
      formData.append("otro", this.transaccion.otro);
      formData.append("cambio", this.transaccion.cambio);
      formData.append("iva", this.transaccion.iva);
      formData.append("total_impuesto", this.transaccion.total_impuesto);
      formData.append("total_pagado", this.transaccion.total_pagado);
      formData.append("fecha", this.transaccion.fecha);
      formData.append("observaciones", this.transaccion.observaciones);
      formData.append(
        "transferencia_bancaria",
        this.transaccion.transferencia_bancaria
      );

      if (this.transaccion.imagen) {
        formData.append("file", this.transaccion.imagen);
      }
      this.formData = formData;
    },
  },
  mounted() {
    this.maxDate = this.mixing.hoy;
    this.transaccion.fecha = this.mixing.hoy;
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.getDocumentoIdentidad();
    this.getMetodosPago();
    this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
    this.transaccion.usuario_id = this.mixing.id;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
function cantidadNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function precioNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function descuentoNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function subtotalImpuestoNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function cantidadParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.cantidad;
  }
}
function precioParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.precio;
  }
}
function costoParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.costo;
  }
}
function descuentoParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.montoDescuento;
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/components/agGridStyle.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
.table-active > td {
  background-color: #ff7676 !important;
  color: white;
}
.table-active > td > button {
  background-color: #ff7676 !important;
  color: white;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100 - 27.3rem);
}
.my-0-5 {
  margin: 0.5rem !important;
  margin-left: 0px !important;
}
#card-pv {
  .card-body {
    padding: 0.8rem;
  }
}
.hr {
  margin-top: 0.1rem;
  margin-bottom: 0.25rem;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}
[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.contenedor-imagen {
  height: 10rem;
  background: #fff;
}

.contenedor-imagen img {
  width: 100%;
  height: 100%;
}
.custom-select:disabled {
  color: #6e6b7b;
  font-weight: 500;
}

.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $primary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}

@media screen and (max-width: 1600px) {
  .contenedor-imagen img {
    height: 120px;
  }
}

.btnBuscarArticulo {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .btnBuscarArticulo {
    margin-top: 0;
  }
}

.contenedor-imagen {
  height: 10.6rem;
  background: #fff;
}
</style>