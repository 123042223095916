import {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BPagination,
    BFormDatepicker,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import Listado from "../views/Listado.vue";
  import CompraServices from "../services/index";
  import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
  export default {
    components: {
      BCard,
      BCardText,
      BButton,
      BFormGroup,
      BFormInput,
      Listado,
      BRow,
      BCol,
      BPagination,
      ModalPdfTransaccion,
      BFormDatepicker,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        loading: false,
        items: [],
        item: [],
        buttonDisabled: true,
        searchQuery: "",
        total: 0,
        tipo_transaccion: "COMPRA",
        // pdf
        accionModalPdf: false,
        pdf: "",
        pdfBase64: "",
        fecha: "",
        usuario_id: null,
      };
    },
    created() {},
    methods: {
      // SERVICES
      async listar() {
        this.loading = true;
        const params={
          tipo_transaccion:this.tipo_transaccion,
          sede_id:this.mixing.sede_id,
          fecha:this.fecha,
          usuario_id:this.usuario_id,
        }
        try {
          const response = await this.listarService(
            params
          );
          // const response = await CompraServices.get(
          //   this.tipo_transaccion,
          //   this.mixing.sede_id,
          //   this.fecha,
          //   this.usuario_id,
          // );
          this.items = response.data;
          this.item = [];
          this.buttonDisabled = true;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.$bvToast.toast(
            err.response.data.message,
            {
              title: "ERROR!",
              variant: "danger",
              solid: false,
            }
          );
        }
      },
      async verPdf(id) {
        try {
          const response = await CompraServices.findOnePdf(id);
          this.pdfBase64 = response.data.data;
          this.pdf =
            "data:application/pdf;base64," + response.data.data + "#toolbar=0";
          this.accionModalPdf = true;
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
      },
      cerrarModalPdf() {
        this.accionModalPdf = false;
        this.pdf = "";
        this.pdfBase64 = "";
      },
      async eliminar(id) {
        try {
          const response = await CompraServices.destroy(id);
          this.$bvToast.toast("Se anulo correctamente.", {
            title: "OK!",
            variant: "success",
            solid: false,
          });
          this.listar();
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
      },
      handlePageChange(value) {
        this.currentPage = value;
        this.listar();
      },
      selectItemVenta(item, bool) {
        this.item = item;
        this.buttonDisabled = bool;
      },
      updateSearchQuery(val) {
        this.$refs.listadoRef.updateSearchQuery(val);
      },
      confirmarEliminar(item) {
        this.$bvModal
          .msgBoxConfirm(
            "Confirme que desea anular : " +
              item.comprobante +
              " : " +
              item.numero_comprobante,
            {
              title: "ANULAR COMPRA ?",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Si, Eliminar",
              cancelTitle: "Cancelar",
              cancelVariant: "outline-dark",
              hideHeaderClose: false,
            }
          )
          .then((value) => {
            if (value) {
              this.eliminar(item.id);
            }
          });
      },
      textFormatter(value) {
        return value.toUpperCase();
      },
    },
    mounted() {
      // this.usuario_id = this.mixing.id;
      this.fecha = this.mixing.hoy;
      // this.listar();
    },
  };